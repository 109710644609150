import React from 'react';
import ReactDOM from 'react-dom/client';
import { Header } from './Header';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { registerServiceWorker } from './utils/serviceworker'

import "./i18nextConf"
import { App, Phone } from './Pages/App';
import { Home } from './Pages/Home';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/web" element={<App />} />
        <Route path="/web/phone/:phoneId" element={<Phone />} />
      </Routes>
    </BrowserRouter>
  </>
);
registerServiceWorker()
