import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener, initializeFirebase } from './firebase';

export const Notification = ({ handleChangeNotification }) => {
    const [notification, setNotification] = useState({ title: '', body: '' });

    const notify = () => toast(`${notification?.title}\n${notification?.body}`, {
        icon: '🔔',
        style: {
            borderRadius: '12px',
            background: '#333',
            color: '#fff',
            padding: "20px",
            boxShadow: "0px 4px 12px #00000040"
        },
    });

    useEffect(() => {
        // Initialize Firebase and request for token only once when the component mounts
        initializeFirebase();
        requestForToken({ handleChangeNotification: handleChangeNotification });

        // Setup the message listener to update the notification state when a new message is received
        onMessageListener()
            .then((payload) => {
                setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
            })
            .catch((err) => console.log('failed: ', err));
    }, []); // Empty dependency array ensures the effect runs only once on mount

    useEffect(() => {
        // Notify when the notification state changes
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    return (
        <Toaster />
    );
};
