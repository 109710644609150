// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

const isMessagingSupported = 'serviceWorker' in navigator && 'PushManager' in window;

export let messaging = null;

export function initializeFirebase() {
    if (isMessagingSupported) {
        var firebaseConfig = {
            apiKey: "AIzaSyATrZusUm6irRetN5fODWKpPH5KhScX6F8",
            authDomain: "watracker-fd1e5.firebaseapp.com",
            databaseURL: "https://watracker-fd1e5-default-rtdb.firebaseio.com",
            projectId: "watracker-fd1e5",
            storageBucket: "watracker-fd1e5.appspot.com",
            messagingSenderId: "419482152006",
            appId: "1:419482152006:web:ab54a6ab76bf4203762e47",
            measurementId: "G-54Z7N81H8Q"
        };

        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);

        messaging = getMessaging();
    } else {
        // FCM is not supported on this browser
        console.log('Firebase Cloud Messaging is not supported on this browser.');
    }
}

export async function requestForToken({ handleChangeNotification }) {
    if (!isMessagingSupported) {
        return Promise.reject(new Error('Firebase Cloud Messaging is not supported on this browser.'));
    }

    if (typeof handleChangeNotification !== 'function') {
        return Promise.reject(new Error('handleChangeNotification must be a valid function.'));
    }

    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BEXVb-9b6-hREd8PV1gUU9CzJBEBxC2w7RtS1KEmEOTjdu0p6qa3suyUtgLIvkGrLWAYV0xu-4nxFebUE5sdSAU' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            console.log(localStorage.getItem("userId"))
            if (localStorage.getItem("userId")) {
                fetch(`https://dev.supersky.app/watracker/userUpdateFCM?userId=${localStorage.getItem("userId")}&userFCM=${currentToken}`)
            }
            handleChangeNotification(true);
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}


export function onMessageListener() {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
}
