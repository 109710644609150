import { useTranslation } from "react-i18next";
import i18n from "../i18nextConf";
import PhoneInput from "react-phone-number-input";
import { Notification } from "../utils/notification"
import { useEffect, useRef, useState } from "react";
import 'react-phone-number-input/style.css'
import { IoAdd, IoBackspaceOutline, IoArrowForwardSharp, oBarChart, IoCaretBackCircle, IoCheckmarkCircleOutline, IoChevronForwardSharp, IoInformation, IoInformationCircleOutline, IoLockClosed, IoNotifications, IoNotificationsCircleOutline, IoPieChart, IoPieChartOutline, IoTimeOutline, IoTrash, IoTime, IoStar, IoStarHalf, IoStarSharp, IoStarHalfSharp, IoStarOutline } from "react-icons/io5"
import { MdCallMade, MdCallReceived, MdTimer } from "react-icons/md"
import { toast } from "react-hot-toast";
import 'react-phone-number-input/style.css'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import QRCode from 'react-qr-code';
import Skeleton from 'react-loading-skeleton';
import { AppHeader, formatUnixTime, formatUnixTimeToHourMinute, formatUnixTimeToHoursMinutesSeconds, getTimezoneOffsetString, timeAgo } from "../utils/consts";

export const App = () => {
    const { t } = useTranslation();
    const lngs = {
        en: { nativeName: 'English' },
        de: { nativeName: 'Deutsch' },
        tr: { nativeName: 'Türkçe' }
    };
    const [notificationStatus, setNotificationStatus] = useState()
    const handleChangeNotification = (status) => {
        setNotificationStatus(status)
    }
    const [userData, setUserData] = useState()
    const [userEmail, setUserEmail] = useState()
    const [verificationCodeStatus, setVerificationCodeStatus] = useState(false)
    const [verificationCode, setVerificationCode] = useState()
    const sendCode = () => {
        fetch(`https://dev.supersky.app/watracker/userAuthWeb?userEmail=${userEmail}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setVerificationCodeStatus(result.responseStatus)
                    if (result.responseStatus) {
                        toast.success(t("codesent"), {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                    }
                    else {
                        toast.error(t("checkemail"), {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const verify = () => {
        fetch(`https://dev.supersky.app/watracker/userAuthWebVerify?userDeviceId=${verificationCode}`)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.responseStatus) {
                        toast.success(t("logged"), {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                        localStorage.setItem("userId", result.userId)
                        getUserData()
                    }
                    else {
                        toast.error(t("codewrong"), {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const getUserData = () => {
        fetch(`https://dev.supersky.app/watracker/userData?userId=${localStorage.getItem("userId")}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setUserData(result)
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const [addPhoneNameInput, setAddPhoneNameInput] = useState();
    const [addPhoneNumberInput, setAddPhoneNumberInput] = useState();
    const phoneCreate = () => {
        fetch(`https://dev.supersky.app/watracker/phoneCreate?phoneName=${addPhoneNameInput}&phoneNumber=${addPhoneNumberInput}&userId=${localStorage.getItem("userId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        toast.success("Phone added.", {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                        setAddNumberState(false)
                        getUserData()
                    }
                    else {
                        toast.error(t(res.responseMessage), {
                            duration: 3000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const phoneDelete = (phoneId) => {
        fetch(`https://dev.supersky.app/watracker/phoneDelete?phoneId=${phoneId}&userId=${localStorage.getItem("userId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    toast.success("Phone deleted.", {
                        duration: 6000,
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                            padding: "20px",
                            boxShadow: "0px 4px 12px #00000040"
                        },
                    })
                    getUserData()
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const [verifyPhoneId, setVerifyPhoneId] = useState();
    const [sessionData, setSessionData] = useState();
    const SessionGet = (phoneId) => {
        if (refreshRef.current) {
            clearInterval(refreshRef.current);
        }
        setSessionData()
        fetch(`https://dev.supersky.app/watracker/sessionBlank`)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.sessionId) {
                        fetch(`https://dev.supersky.app/watracker/sessionUpdatePhoneId?phoneId=${phoneId}&sessionVerificationCode=${res.sessionVerificationCode}`)
                            .then(res2 => res2.json())
                            .then(
                                (res2) => {
                                    if (res.sessionId) {
                                        setVerifyPhoneId(phoneId);
                                        refreshRef.current = setInterval(() => {
                                            SessionData(res.sessionId);
                                        }, 3000);
                                    }
                                },
                                (error) => {
                                }
                            )

                    }
                },
                (error) => {
                }
            )
    }
    const refreshRef = useRef(null); // Create a ref for the interval

    const SessionData = (sessionId) => {
        fetch(`https://dev.supersky.app/watracker/sessionData?sessionId=${sessionId}`)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.sessionStatus == 1) {
                        console.log("verified")
                        if (refreshRef.current) {
                            clearInterval(refreshRef.current);
                        }
                        getUserData();
                        toast.success("Tracking started!", {
                            duration: 6000,
                            style: {
                                borderRadius: '12px',
                                background: '#333',
                                color: '#fff',
                                padding: "20px",
                                boxShadow: "0px 4px 12px #00000040"
                            },
                        })
                    }
                    else {
                        if (res.sessionId) {
                            setSessionData(res);
                        }
                        else {
                            setSessionData(false);
                        }
                    }
                },
                (error) => {
                }
            )
    }
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (localStorage.getItem("userId")) {
            getUserData()
        }
        console.log(searchParams.get("payment"))
        if (searchParams.get("payment") == "true") {
            purchaseSuccess(searchParams.get("purchaseProduct"))
        }
    }, [])
    const purchaseSuccess = (product) => {
        fetch(`https://dev.supersky.app/watracker/userPurchase?userId=${localStorage.getItem("userId")}&purchaseType=${product}`)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.href = "https://watracker.app/web"
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const [addNumberState, setAddNumberState] = useState(false)
    const purchase = () => {
        fetch(`https://dev.supersky.app/watracker/userPurchaseWeb?userId=${localStorage.getItem("userId")}&purchaseProduct=${selectedPlan}&userEmail=${userData.userEmail}`)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.href = result.sessionUrl
                },
                (error) => {
                    console.log(error);
                }
            )
    }
    const [selectedPlan, setSelectedPlan] = useState("monthly3")
    return (
        <div className="app">
            <AppHeader title={localStorage.getItem("userId") ? "Home" : "WhatsApp Tracker"} />
            {
                !localStorage.getItem("userId") ?
                    <div className="joinus">
                        <span className="title">Start Tracking Your WhatsApp Contacts!</span>
                        <span className="desc">Track your loved ones on WhatsApp</span>
                        <span className="desc">Get instant notifications when they are online or offline</span>
                        <div className="awards">
                            <img className="award" src="award.png" />
                            <img className="award" src="ipadaward.png" />
                        </div>
                        <iframe className="video" src="https://www.youtube.com/embed/DPP81VK5voU?si=goXpFXBLDZAJG3ST" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <div className="screens">
                            <img src="f2.png" />
                            <img src="f3.png" />
                            <img src="f4.png" />
                            <img src="f5.png" />
                        </div>
                        <div className="customer_reviews">
                            <div className="review">
                                <span className="name">Mustafa</span>
                                <span className="comment">Nice really works! I can track only by phone number.</span>
                                <div className="stars">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                </div>
                            </div>
                            <div className="review">
                                <span className="name">Anna</span>
                                <span className="comment">This app really works but you have scan qr code.</span>
                                <div className="stars">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                </div>
                            </div>
                            <div className="review">
                                <span className="name">Johannes</span>
                                <span className="comment">Affordable price compared to other apps, thanks for the service.</span>
                                <div className="stars">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarHalfSharp />
                                </div>
                            </div>
                            <div className="review">
                                <span className="name">Boris</span>
                                <span className="comment">It works for every country in WhatsApp, also tracks online duration of phone.</span>
                                <div className="stars">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                </div>
                            </div>
                        </div>
                        <div className="star_review">
                            <div className="stars">
                                <IoStarSharp />
                                <IoStarSharp />
                                <IoStarSharp />
                                <IoStarSharp />
                                <IoStarHalfSharp />
                            </div>
                            <span>4.2 — More +10K Review</span>

                        </div>
                        <img className="seenon" src="seenon.png" />
                    </div>
                    : null
            }
            <div className="notification-status area">
                {notificationStatus ?
                    <IoCheckmarkCircleOutline className="icon check" />
                    :
                    <span className="loader icon" style={{ width: "18px", height: "18px", border: "2px solid #fff" }}></span>
                }
                <span>{notificationStatus ? t("getnotifications") : t("checkingnotifications")}</span>
            </div>
            {localStorage.getItem("userId") ?
                null
                :
                <div className="email-verification area">
                    <span>{t("verifyemail")}</span>
                    <br />{
                        !verificationCodeStatus ?
                            <div className="email-verification_inner">
                                <input className="input" type="text" placeholder={t("email")} onChange={e => setUserEmail(e.target.value)} />
                                <span className="send" onClick={() => sendCode()}>{t("sendcode")}</span>
                            </div>
                            :
                            <div>
                                <div className="email-verification_inner">
                                    <input className="input" type="text" placeholder={t("enterverificationcode")} onChange={e => setVerificationCode(e.target.value)} />
                                    <span className="send" onClick={() => verify()}>{t("verify")}</span>
                                </div>
                            </div>
                    }


                </div>
            }
            {userData ?
                <>
                    {userData.userPackageTime <= 0 ?
                        <div className="user-plan area">
                            <span className="alt_info">{t("notactivesubscription")}</span>
                            <div className="perks">
                                <div className="perk">
                                    <IoNotificationsCircleOutline className="icon" />
                                    <span>{t("onlineofflinenotifications")}</span>
                                </div>
                                <div className="perk">
                                    <IoPieChartOutline className="icon" />
                                    <span>{t("periodicanalytics")}</span>
                                </div>
                                <div className="perk">
                                    <IoTimeOutline className="icon" />
                                    <span>{t("statustrackhistory")}</span>
                                </div>
                                <div className="perk">
                                    <IoInformationCircleOutline className="icon" />
                                    <span>{t("detailedtrackinformations")}</span>
                                </div>
                            </div>
                            <div className="plans">
                                <div className={`subscription ${selectedPlan == "weekly3" ? "subscription_selected" : null}`} onClick={() => setSelectedPlan("weekly3")}>
                                    <div className="sub_details">
                                        <span>{t("weekly")}</span>
                                        <span>3 {t("numbers")}</span>
                                    </div>
                                    <span className="price">{t("weekly3price")}</span>
                                </div>
                                <div className={`subscription ${selectedPlan == "weekly5" ? "subscription_selected" : null}`} onClick={() => setSelectedPlan("weekly5")}>
                                    <div className="sub_details">
                                        <span>{t("weekly")}</span>
                                        <span>5 {t("numbers")}</span>
                                    </div>
                                    <span className="price">{t("weekly5price")}</span>
                                </div>
                                <div className={`subscription ${selectedPlan == "monthly3" ? "subscription_selected" : null}`} onClick={() => setSelectedPlan("monthly3")}>
                                    <div className="sub_details">
                                        <span>{t("monthly")}</span>
                                        <span>3 {t("numbers")}</span>
                                    </div>
                                    <span className="price">{t("monthly3price")}</span>
                                </div>
                                <div className={`subscription ${selectedPlan == "monthly5" ? "subscription_selected" : null}`} onClick={() => setSelectedPlan("monthly5")}>
                                    <div className="sub_details">
                                        <span>{t("monthly")}</span>
                                        <span>5 {t("numbers")}</span>
                                    </div>
                                    <span className="price">{t("monthly5price")}</span>
                                </div>
                            </div>
                            <span className="continue" onClick={() => purchase(selectedPlan)}>{t("continue")}</span>
                        </div>
                        : null}
                    {
                        userData.phoneList.length > 0 ?
                            <div className="phone-list">
                                <span className="title">Phone List</span>
                                <div className="phones">
                                    {userData.phoneList.map(i =>
                                        <Link to={i.phoneStatus ? `/web/phone/${i.phoneId}` : ""} className={i.phoneStatus ? "item item_active" : "item"} style={{ cursor: i.phoneStatus ? "pointer" : "default" }}>
                                            <div className="info">
                                                <div className="id">
                                                    <img className="avatar" src={i.phoneImage} />
                                                    <div className="details">
                                                        <span>{i.phoneName}</span>
                                                        <span className="phone_number">+{i.phoneNumber}</span>
                                                    </div>
                                                </div>
                                                {
                                                    i.phoneStatus ?

                                                        <div className="chev">

                                                            <span className="last_seen">{
                                                                i.trackList.length > 0 ? timeAgo(i.trackList[0].trackCreatedDate) : "No data yet"}</span>
                                                            <IoChevronForwardSharp className="actions action_details" />
                                                        </div>

                                                        :
                                                        <IoTrash className="actions action_trash" onClick={() => phoneDelete(i.phoneId)} />
                                                }
                                            </div>

                                            {
                                                !i.phoneStatus ?
                                                    <div className="scan-qr">
                                                        <span>You need to scan the QR code on your WhatsApp to start tracking.</span>
                                                        {
                                                            verifyPhoneId == i.phoneId ? sessionData ?
                                                                <div className="scan-details">
                                                                    <span>{t("whatsappstep1")}</span>
                                                                    <span>{t("whatsappstep2")}</span>
                                                                    <span>{t("whatsappstep3")}</span>
                                                                    <span>{t("whatsappstep4")}</span>
                                                                    {Math.floor(Date.now() / 1000 - sessionData.sessionQrCodeUpdateDate) < 60 ? <QRCode className="app-qr" value={sessionData.sessionQrCode} size={186} /> : <Skeleton className="app-qr" width={186} height={186} />}
                                                                    <div className="showqrbtn" onClick={() => {
                                                                        setVerifyPhoneId()
                                                                        setSessionData(0)
                                                                        clearInterval(refreshRef.current)
                                                                    }}>
                                                                        <span>Close</span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <span className="loader icon center" style={{ width: "18px", height: "18px", border: "2px solid #fff" }}></span>
                                                                :
                                                                <div className="showqrbtn" onClick={() => {
                                                                    SessionGet(i.phoneId);
                                                                }
                                                                }>
                                                                    <span>Show QR Code</span>
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    null

                                            }
                                        </Link>
                                    )}
                                </div>

                            </div>
                            :
                            <div className="phone-not-added">
                                <span className="info">{t("phonenotadded")}</span>
                            </div>

                    }
                    <div className="add-number" onClick={() => {
                        if (userData.userPackageTime <= 0) {
                            toast.error(t("buypremiumerror"), {
                                duration: 3000,
                                style: {
                                    borderRadius: '12px',
                                    background: '#333',
                                    color: '#fff',
                                    padding: "20px",
                                    boxShadow: "0px 4px 12px #00000040"
                                },
                            })
                        }
                        else {
                            setAddNumberState(true)
                        }
                    }}>
                        <IoAdd className="icon" />
                        <span>{t("addnumber")}</span>
                    </div>
                </>
                :
                localStorage.getItem("userId") ?
                    < span className="loader icon center" style={{ width: "18px", height: "18px", border: "2px solid #fff" }}></span>
                    :
                    null

            }
            {
                addNumberState ?
                    <div className="popup" >
                        <div className="popup_overlay" onClick={() => setAddNumberState(false)}></div>
                        <div className="popup_inner" onClick={() => { }}>
                            <span className="popup_title">Add Number</span>
                            <div className="popup_content">
                                <>
                                    <input type="text" placeholder="Name"
                                        onChange={e => setAddPhoneNameInput(e.target.value)} className="input phone_name" />
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        onChange={e => setAddPhoneNumberInput(e)} />
                                    <div className="add-number" onClick={() => phoneCreate()}><span>{t("addnumber")}</span></div>
                                </>
                            </div>
                            <br />
                        </div>
                    </div>
                    : null
            }
            <Notification handleChangeNotification={handleChangeNotification} />
            {
                <div className="languages">
                    <span>Languages</span>
                    <div className="list">
                        {Object.keys(lngs).map((lng) => (
                            <span className="item" key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                {lngs[lng].nativeName}
                            </span>
                        ))}
                    </div>
                </div>
            }
        </div >
    )
}

export const Phone = () => {
    const { phoneId } = useParams();

    const [phoneData, setPhoneData] = useState()
    const getPhoneData = () => {
        fetch(`https://dev.supersky.app/watracker/phoneData?phoneId=${phoneId}&timeZone=${getTimezoneOffsetString()}`)
            .then(res => res.json())
            .then(
                (res => {
                    setPhoneData(res)
                }),
                (error) => {
                    console.log(error);
                }
            )
    }
    const navigate = useNavigate();

    const phoneDelete = (phoneId) => {
        fetch(`https://dev.supersky.app/watracker/phoneDelete?phoneId=${phoneId}&userId=${localStorage.getItem("userId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    toast.success("Phone deleted.", {
                        duration: 6000,
                        style: {
                            borderRadius: '12px',
                            background: '#333',
                            color: '#fff',
                            padding: "20px",
                            boxShadow: "0px 4px 12px #00000040"
                        },
                    })
                    navigate("/web");
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    useEffect(() => {
        getPhoneData()
    }, [])
    return (

        <div className="app">
            <AppHeader title="Phone Details" />
            {
                phoneData ?
                    localStorage.getItem("userId") == phoneData.userId ?
                        <div className="app_phone">
                            <img className="avatar" src={phoneData.phoneImage} />
                            <span>{phoneData.phoneName}</span>
                            <span>+{phoneData.phoneNumber}</span>
                            <div className="phone_track_history">
                                <span className="title">Track History</span>
                                <div className="list">
                                    {phoneData.trackList.map(i =>
                                        <div className="item_outer">
                                            <span>{formatUnixTime(i.trackCreatedDate)}</span>
                                            <div className="item">
                                                <div className="section">
                                                    <div className="prefix online">
                                                        <MdCallMade className="icon" />
                                                        <span>Online</span>
                                                    </div>
                                                    <span>{formatUnixTimeToHourMinute(i.trackCreatedDate)}</span>
                                                </div>
                                                <div className="section">
                                                    <div className="prefix offline">
                                                        <MdCallReceived className="icon" />
                                                        <span>Offline</span>
                                                    </div>
                                                    <span>{formatUnixTimeToHourMinute(i.trackCreatedDate + i.trackSeconds)}</span>
                                                </div>
                                                <div className="section">
                                                    <div className="prefix time">
                                                        <IoTimeOutline className="icon" />
                                                        <span>Time</span>
                                                    </div>
                                                    <span>{formatUnixTimeToHoursMinutesSeconds(i.trackSeconds)}</span>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>
                            <div className="phone_actions">
                                <div className="phone_delete_btn" onClick={() => phoneDelete(phoneData.userId)}>
                                    <IoTrash />
                                    <span>Delete Phone</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <span>No permission</span>
                        </div>
                    :
                    <span className="loader icon center" style={{ width: "18px", height: "18px", border: "2px solid #fff" }}></span>
            }
        </div >
    )
}
