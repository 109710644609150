import { Link } from "react-router-dom";
import logo from "../../src/timeleft.png";

export function timeAgo(unixTimestamp) {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    const elapsedSeconds = currentTimestamp - unixTimestamp;

    if (elapsedSeconds < 60) {
        return `${elapsedSeconds} second${elapsedSeconds !== 1 ? 's' : ''} ago`;
    } else if (elapsedSeconds < 3600) {
        const minutes = Math.floor(elapsedSeconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (elapsedSeconds < 86400) {
        const hours = Math.floor(elapsedSeconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
        const days = Math.floor(elapsedSeconds / 86400);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
}
export function getTimezoneOffsetString() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
    const offsetMinutesPart = Math.abs(offsetMinutes % 60);

    // Determine the sign of the offset (positive or negative)
    const sign = offsetMinutes > 0 ? '-' : '';

    // Convert the parts to strings and pad with zeros if needed
    const offsetHoursString = String(offsetHours).padStart(2, '0');
    const offsetMinutesString = String(offsetMinutesPart).padStart(2, '0');

    // Construct the offset string in the format: ±HH:mm:ss
    const offsetString = `${sign}${offsetHoursString}:${offsetMinutesString}:00`;

    return offsetString;
}
export function formatUnixTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
}
export function formatUnixTimeToHourMinute(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const hours = date.getHours().toString().padStart(2, '0'); // Get hours and pad with 0 if needed
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with 0 if needed
    return `${hours}:${minutes}`;
}
export function formatUnixTimeToHoursMinutesSeconds(unixTimestamp) {
    const totalSeconds = Math.floor(unixTimestamp);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const parts = [];

    if (hours > 0) {
        parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
    }

    if (minutes > 0) {
        parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
    }

    if (seconds > 0) {
        parts.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);
    }

    return parts.join(' ');
}
export const AppHeader = ({ title }) => {
    return (
        <div className="app_header">
            <div className="inner">
                <Link to="/web" className="logo">
                    <img src={logo} />
                    <span>WaTracker</span>
                </Link>
                <span className="page_title">
                    {title}
                </span>
            </div>
        </div>
    )
}